module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"4","matomoUrl":"https://youmeokay.io/matomo/","siteUrl":"https://bericht.sptg.de","disableCookies":true,"dev":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stiftung Polytechnische Gesellschaft","short_name":"Stiftung Polytechnische Gesellschaft","lang":"de","display":"standalone","icon":"./src/assets/images/icon.png","start_url":"/","background_color":"#FFF","theme_color":"#000","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8a813b18b7dbef1b786ee9f2d7b0410d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
